import * as React from "react";
import { Field, FieldAttributes, FieldProps } from 'formik';
import { Dropdown, IDropdownOption } from '@fluentui/react';

// https://github.com/kmees/formik-office-ui-fabric-react


// https://fabricweb.z5.web.core.windows.net/oufr/7.153.2/#/examples/textfield
export const DropdownControl: React.FunctionComponent<FieldAttributes<any>> = (props) => {
    return (
        <Field {...props}>
            {({ field, form, meta }: FieldProps) => (
              <Dropdown
                  selectedKey={field.value}
                  errorMessage={meta.touched && meta.error ? meta.error : undefined}
                  {...field}
                  {...props}
                  styles={{title: {background: meta.error ? 'red' : 'none'}}}
                  onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                    form.setFieldValue(field.name, item.key);
                    console.log(event.currentTarget)
                    console.log(item.key)
                  }}
              />
            )}
        </Field>
    );
}




