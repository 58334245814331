import { App } from "./components/App";
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { ThemeProvider } from "@fluentui/react";
import * as React from "react";
import * as ReactDOM from "react-dom";

initializeIcons();

let isOfficeInitialized = false;
const title = "Skydoc Attribute Editor";

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <ThemeProvider>
        <Component title={title} isOfficeInitialized={isOfficeInitialized} />
      </ThemeProvider>
    </AppContainer>,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */
/* Microsoft recommends to use Office.onReady() instead of Office.initialize - https://docs.microsoft.com/en-us/office/dev/add-ins/develop/initialize-add-in
Office.initialize = () => {
  isOfficeInitialized = true;
  render(App);
};*/

Office.onReady(function (info) {
  console.log(`Office.js is now ready in ${info.host} on ${info.platform}`);
  isOfficeInitialized = true;
  // https://stackoverflow.com/questions/50978450/enabling-extended-error-logging-in-office-add-ins-to-see-full-statements-on-err
  OfficeExtension.config.extendedErrorLogging = true;
  render(App);
});

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(NextApp);
  });
}
