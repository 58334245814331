import * as React from "react";
import { DefaultButton, Stack } from "@fluentui/react";
import { InputControl } from "./InputControl";
import { DropdownControl } from "./DropdownControl";

export const DateComponent: React.FunctionComponent<{
  label: string;
  date: string;
  timeBase: string;
  timeBasedOptions: any;
  onClickEOD: any;
  onClickNow: any;
  onClickClear: any;
}> = (props) => {
  const { label, date, timeBase, timeBasedOptions, onClickEOD, onClickNow, onClickClear } = props;

  if (!timeBase || timeBase === "") {
    return <Stack horizontal>
              <Stack.Item>
                <InputControl label={label} name={date}/>
              </Stack.Item>
              <Stack.Item align="end">
                <DefaultButton
                  text="Now"
                  onClick={(e) => {
                    e.preventDefault();
                    onClickNow();
                  }}
                />
              </Stack.Item>
              <Stack.Item align="end">
                <DefaultButton
                  text="Clear"
                  onClick={(e) => {
                    e.preventDefault();
                    onClickClear();
                  }}
                />
              </Stack.Item>
            </Stack>
  }
// mask="9999-99-99 99:99" 
  return (
    <Stack>
      <Stack.Item>
        <InputControl label={label} name={date} />
      </Stack.Item>
      <Stack horizontal>
        <Stack.Item align="stretch">
          <DefaultButton
            text="Now"
            onClick={(e) => {
              e.preventDefault();
              onClickNow();
            }}
          />
          <DefaultButton
            text="Clear"
            onClick={(e) => {
              e.preventDefault();
              onClickClear();
            }}
          />
          <DefaultButton
            text="EOD"
            onClick={(e) => {
              e.preventDefault();
              onClickEOD();
            }}
          />
        </Stack.Item>
        <DropdownControl name={timeBase} options={timeBasedOptions} />
      </Stack>
    </Stack>
  );
};
