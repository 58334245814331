import * as React from "react";
import { useFormikContext } from 'formik';
import { PeoplePicker } from "../PeoplePicker";
import { getIn } from "formik";
import { GraphService } from "../../../helpers/graph.service";
import { IPersonaProps, Label } from "@fluentui/react";

export const PoeplePickerControl: React.FunctionComponent<{ label?: string, required?: boolean, name: string, graphService: GraphService, fieldDepartment: string, fieldTitle: string }> = (props) => {
    const { label, name, graphService, required, fieldTitle, fieldDepartment } = props;
    const { values, setFieldValue } = useFormikContext();

    const value = getIn(values, name);
    const selectedUser = value ? [{ text: value }] : [];   // : [];    // : [{text: "Dirk Schmitz"}];

    const itemSelected = async (user: IPersonaProps) => {
        let value = null;
        let department = undefined;
        let title = undefined;
        if (user) {
            value = user.text;
            department = await graphService.getUserExtAttrib2(user.id);
            title = await graphService.getUserDepartment(user.id);
            // The fields are switch in the current Azure AD and will be changed in Sept 2022
            //department = await graphService.getUserDepartment(user.id);
            //title = await graphService.getUserTitle(user.id);
        }
        setFieldValue(name, value, true);
        setFieldValue(fieldTitle,title === undefined || title === null ? "" : title,true);
        setFieldValue(fieldDepartment,department === undefined || department === null ? "" : department,true);
    }

    return (
        <React.Fragment>
            {label && (
                <Label required={required}>{label}</Label>
            )}
            <PeoplePicker key={selectedUser.length} selectedUsers={selectedUser} graphService={graphService} itemSelected={itemSelected} />
        </React.Fragment>
    );
}




