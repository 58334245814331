import * as React from "react";
import { IPersonaProps } from "@fluentui/react/lib/Persona";
import { IBasePickerSuggestionsProps, NormalPeoplePicker } from "@fluentui/react/lib/Pickers";
import { GraphService } from "../../helpers/graph.service";

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: "Suggested People",
  mostRecentlyUsedHeaderText: "Suggested Contacts",
  noResultsFoundText: "No results found",
  loadingText: "Loading",
  showRemoveButtons: true,
  suggestionsAvailableAlertText: "People Picker Suggestions available",
  suggestionsContainerAriaLabel: "Suggested contacts",
};

export const PeoplePicker: React.FunctionComponent<{
  graphService: GraphService;
  selectedUsers: IPersonaProps[];
  itemSelected?: (user: IPersonaProps) => void;
}> = (props) => {
  const { graphService, selectedUsers, itemSelected } = props;

  const picker = React.useRef(null);

  const onFilterChanged = (
    filterText: string, 
    currentPersonas: IPersonaProps[],
    limitResults?: number
  ): Promise<IPersonaProps[]> => {
    currentPersonas = currentPersonas;
    if (filterText) {
      return graphService.getUserBySearchCriteria(filterText, limitResults);
    } else {
      return new Promise<IPersonaProps[]>((resolve) => resolve([]));
    }
  };

  const onChange = (items?: IPersonaProps[]): void => {
    const array = items ?? [];

    let value = null;
    if (array.length > 0) {
      value = array[0];
    }
    itemSelected(value);
  };

  return (
    <NormalPeoplePicker
      // eslint-disable-next-line react/jsx-no-bind
      onResolveSuggestions={onFilterChanged}
      // eslint-disable-next-line react/jsx-no-bind
      getTextFromItem={getTextFromItem}
      pickerSuggestionsProps={suggestionProps}
      itemLimit={1}
      onChange={onChange}
      defaultSelectedItems={selectedUsers}
      className={"ms-PeoplePicker"}
      key={"normal"}
      selectionAriaLabel={"Selected contacts"}
      removeButtonAriaLabel={"Remove"}
      componentRef={picker}
      resolveDelay={300}
    />
  );
};

function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}
