import * as React from "react";
import Progress from "./Progress";
import { Formik } from "formik";
import * as Yup from "yup";
import { InputControl } from "./fields/InputControl";
import { DropdownControl } from "./fields/DropdownControl";
import { DefaultButton } from "@fluentui/react";
import { DateComponent } from "./fields/DateComponent";
import { GraphService } from "../../helpers/graph.service";
import { PoeplePickerControl } from "./fields/PeoplePickerControl";
import { DateFunctions } from "../../helpers/datefunctions";
import { Errors } from "@azure/msal-common/dist/utils/Constants";
import { values } from "@fluentui/react-hooks/node_modules/@fluentui/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCircleInfo } from '@fortawesome/free-solid-svg-icons'

/* global Word, require */


export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export interface DocumentProperties {
  title?: string;
  subject?: string;
  comments?: string;
  author?: string;
  manager?: string;
  customProperties?: CustomProperties;
}

export enum Status {
  Draft = "Draft",
  Proposed = "Proposed",
  Released = "Released",
  Retired = "Retired",
}

export enum Classification {
  Public = "Public",
  Internal = "Internal",
  Restricted = "Restricted",
  Confidential = "Confidential",
}

export enum Language {
  E = "E",
  D = "D",
  F = "F",
}

export enum TimeBase {
  LT = "LT",
  UTC = "UTC",
}

export enum AttributeLength {
  title = 254,
  comments = 512,
  SGGovDocID = 64,
  SGDocumentType = 32,
  author = 64,
  authorsUnit = 64,
  authorsTitle = 128,
  manager = 64,
  managersUnit = 64,
  managersTitle = 128,
  version = 32,
}

const statusOptions = [
  { key: Status.Draft, text: Status.Draft },
  { key: Status.Proposed, text: Status.Proposed },
  { key: Status.Released, text: Status.Released },
  { key: Status.Retired, text: Status.Retired },
];

const confidentialityOptions = [
  { key: Classification.Public, text: Classification.Public },
  { key: Classification.Internal, text: Classification.Internal },
  { key: Classification.Restricted, text: Classification.Restricted },
  { key: Classification.Confidential, text: Classification.Confidential },
];

const languageOptions = [
  { key: Language.E, text: Language.E },
  { key: Language.D, text: Language.D },
  { key: Language.F, text: Language.F },
];

const timeBaseOptions = [
  { key: TimeBase.LT, text: TimeBase.LT },
  { key: TimeBase.UTC, text: TimeBase.UTC },
];

export interface CustomProperties {
  SGGovDocID?: string;
  SGDocumentType?: string;
  Status?: Status;
  Classification?: Classification;
  Language?: Language;
  AuthorsUnit?: string;
  ManagersUnit?: string;
  AuthorsTitle?: string;
  ManagersTitle?: string;
  Version?: string;
  SGIssueDate?: string;
  SGIssueDateTxt?: string;
  SGEffectiveDate?: string;
  SGEffectiveDateTxt?: string;
  SGEffectiveTime?: string;
  SGEffectiveTimeBase?: TimeBase;
  SGValidity?: string;
  SGValidityDate?: string;
  SGValidUntil?: string;
  SGValidityTime?: string;
  SGValidityTimeBase?: TimeBase;
  SGValidUntilBase?: TimeBase;
  Valid?: string;
}

const dtf = new DateFunctions();

let isDev = false;

const isDevVersion = () => {
  const loc = window.location.href;
  if (loc !==  undefined && loc !== "") {
    isDev = (loc.toString().includes("backend.dev.prop") || loc.toString().includes("localhost"));
  };
}

const getMyTimeBase = (text: string) => {
  if (text.includes("LT")) return TimeBase.LT;
  return text.includes("UTC") ? TimeBase.UTC : undefined;
};

const getFieldValue = (key: string, items: Word.CustomProperty[]) => {
  const index = items.findIndex((p) => p.key === key);
  if (index === -1) return undefined;

  return items[index].value;
};

const getFieldType = (key: string, items: Word.CustomProperty[]) => {
  const index = items.findIndex((p) => p.key === key);
  if (index === -1) return undefined;

  return items[index].type;
};

const getDateAlwaysAsString = (key: string, items: Word.CustomProperty[]) => {
  var data = getFieldValue(key, items);
  return getFieldType(key, items) === "Date" ? dtf.GetFormattedDateString(data) : data === undefined ? "" : data;
};

const isUndefOrEMpty = (text: string) => {
  return text === undefined || text.trim() === "" ? true : false;
};

const getDocumentProperties = (properties: Word.DocumentProperties) => {
  const { customProperties } = properties;
  const { items } = customProperties;

  //dtf.TEST();
  

  const documentProperties: DocumentProperties = {
    title: properties.title,
    subject: properties.subject,
    comments: properties.comments,
    author: properties.author,
    manager: properties.manager,
    customProperties: {
      Language: getFieldValue("Language", items),
      Classification: getFieldValue("Classification", items),
      Status: getFieldValue("Status", items),
      SGGovDocID: getFieldValue("SGGovDocID", items),
      SGDocumentType: getFieldValue("SGDocumentType", items),
      AuthorsUnit: getFieldValue("AuthorsUnit", items),
      AuthorsTitle: getFieldValue("AuthorsTitle", items),
      ManagersUnit: getFieldValue("ManagersUnit", items),
      ManagersTitle: getFieldValue("ManagersTitle", items),
      Version: getFieldValue("Version", items),
      SGIssueDateTxt: getDateAlwaysAsString("SGIssueDateTxt", items),
      SGIssueDate: getDateAlwaysAsString("SGIssueDate", items),
      SGEffectiveDateTxt: getDateAlwaysAsString("SGEffectiveDateTxt", items),
      SGEffectiveDate: getDateAlwaysAsString("SGEffectiveDate", items),
      SGEffectiveTime: getFieldValue("SGEffectiveTime", items),
      SGEffectiveTimeBase: getFieldValue("SGEffectiveTimeBase", items),
      SGValidity: getDateAlwaysAsString("SGValidity", items),
      SGValidityDate: getDateAlwaysAsString("SGValidityDate", items),
      SGValidUntil: getDateAlwaysAsString("SGValidUntil", items),
      SGValidityTime: getFieldValue("SGValidityTime", items),
      SGValidityTimeBase: getFieldValue("SGValidityTimeBase", items),
      SGValidUntilBase: getFieldValue("SGValidUntilBase", items),
    },
  };

  try {
    {
      // Issue Date
      if (isUndefOrEMpty(documentProperties.customProperties.SGIssueDateTxt)) {
        documentProperties.customProperties.SGIssueDateTxt = documentProperties.customProperties.SGIssueDate;
      }

      
      // EffectiveFrom
      var valFrom = dtf.FormatToDateTime_Standard(documentProperties.customProperties.SGEffectiveDateTxt);
      if (isUndefOrEMpty(valFrom)) {
        valFrom = dtf.FormatToDateTime_Standard(documentProperties.customProperties.SGEffectiveDate);
      }
      if (documentProperties.customProperties.SGEffectiveTimeBase === undefined) {
        if (valFrom.toUpperCase().includes("LT")) {
          documentProperties.customProperties.SGEffectiveTimeBase = TimeBase.LT;
        } else if (valFrom.toUpperCase().includes("UTC")) {
          documentProperties.customProperties.SGEffectiveTimeBase = TimeBase.UTC;
        }
      }
      if (dtf.GetTimePart(valFrom) === "" && !isUndefOrEMpty(documentProperties.customProperties.SGEffectiveTime)) {
        valFrom = valFrom + " " + documentProperties.customProperties.SGEffectiveTime;
      }
      valFrom = dtf.FormatToDateTime_Standard(valFrom);
      documentProperties.customProperties.SGEffectiveDateTxt = valFrom;
      documentProperties.customProperties.SGEffectiveDate = valFrom;


      // Valid Until
      var val = documentProperties.customProperties.SGValidUntil;
      if (isUndefOrEMpty(val)) val = documentProperties.customProperties.SGValidity;
      if (isUndefOrEMpty(val)) val = documentProperties.customProperties.SGValidityDate;
      if (val.toUpperCase().includes("LT")) {
        documentProperties.customProperties.SGValidityTimeBase = TimeBase.LT;
      } else if (val.toUpperCase().includes("UTC")) {
        documentProperties.customProperties.SGValidityTimeBase = TimeBase.UTC;
      }
      val = dtf.FormatToDateTime_Standard(val);
      if ((dtf.GetTimePart(val) === "") && !isUndefOrEMpty(documentProperties.customProperties.SGValidityTime)) {
        val = val + " " + documentProperties.customProperties.SGValidityTime;
      }
      documentProperties.customProperties.Valid = val;
      documentProperties.customProperties.SGValidUntil = val;
      documentProperties.customProperties.SGValidity = val;
      documentProperties.customProperties.SGValidityDate = val;

      // Set defaults
      if (isUndefOrEMpty(documentProperties.customProperties.Version))
        documentProperties.customProperties.Version = "0.1";
      if (documentProperties.customProperties.Classification === undefined)
        documentProperties.customProperties.Classification = Classification.Internal;
      if (documentProperties.customProperties.Status === undefined)
        documentProperties.customProperties.Status = Status.Draft;
      if (documentProperties.customProperties.Language === undefined)
        documentProperties.customProperties.Language = Language.E;
      if (documentProperties.customProperties.SGEffectiveTimeBase === undefined)
        documentProperties.customProperties.SGEffectiveTimeBase = TimeBase.LT;
      if (documentProperties.customProperties.SGValidityTimeBase === undefined)
        documentProperties.customProperties.SGValidityTimeBase = TimeBase.LT;

      // Remove LT UTC from displayed text
      documentProperties.customProperties.SGIssueDateTxt = documentProperties.customProperties.SGIssueDateTxt?.replace(
        "LT ",
        ""
      ).replace("UTC ", "");
      documentProperties.customProperties.SGEffectiveDateTxt =
        documentProperties.customProperties.SGEffectiveDateTxt?.replace("LT ", "").replace("UTC ", "");
      documentProperties.customProperties.Valid = documentProperties.customProperties.Valid?.replace("LT ", "").replace(
        "UTC ",
        ""
      );

      // nur zum debuggen
      //documentProperties.customProperties.SGValidityTimeBase = undefined;
    }
  } catch (error) {
    console.log("Error: " + error);
    if (error instanceof OfficeExtension.Error) {
      console.log("Debug info: " + JSON.stringify(error.debugInfo));
    }
  }
  
  return documentProperties;
};


export enum AuthStatus {
  NotLoggedIn = "NotLoggedIn",
  LoginInProcess = "LoginInProcess",
  LoggedIn = "LoggedIn",
}

export const App: React.FunctionComponent<AppProps> = (props) => {
  const { title, isOfficeInitialized } = props;
  const [serviceError, setServiceError] = React.useState<string>();
  const [graphService, setGraphService] = React.useState<GraphService>();
  const [message, setMessage] = React.useState<string>();
  const [documentProperties, setDocumentProperties] = React.useState<DocumentProperties>({
    customProperties: {
      Status: Status.Draft,
      Language: Language.E,
      SGEffectiveTimeBase: TimeBase.LT,
      SGValidityTimeBase: TimeBase.LT,
    },
  });

  React.useEffect(() => {
    Word.run(async (context) => {
      isDevVersion();
      fetch("msalSettings.json")
        .then((response) => {
          return response.json();
        })
        .then((jsondata) => {
          setGraphService(new GraphService(jsondata));
        });
      var properties = context.document.properties;
      context.load(properties.customProperties);
      context.load(properties);
      await context.sync();
      setDocumentProperties(getDocumentProperties(properties));
    }).catch(function (error) {
      console.log("Error: " + error);
      if (error instanceof OfficeExtension.Error) {
        console.log("Debug info: " + JSON.stringify(error.debugInfo));
      }
    });
  }, []);

  const getString = (text: string, maxLength: number) => {
    if (text === undefined || text === "") return "";
    return maxLength < 0 ? text : text.substring(0, maxLength);
  };

  const onSubmit = async (values: DocumentProperties, { setSubmitting }) => {
    await Word.run(async (context) => {
      var docProps = context.document.properties;
      var customProps = context.document.properties.customProperties;
      context.load(docProps);
      context.load(customProps);
      await context.sync();

      // Uli reported bug - Ticket 881 - removed this section. Does not make sense at all - Test relist?
      /*   
      var name = getString(values?.title, -1);
      var val = getString(values?.subject, -1);
      if (name !== "" && val !== "") {
        context.document.properties.customProperties.add(name, val);
      }
      */

      context.document.properties.title = getString(values?.title, AttributeLength.title);
      context.document.properties.subject = getString(values?.subject, AttributeLength.title);
      context.document.properties.comments = getString(values?.comments, AttributeLength.comments);
      context.document.properties.author = getString(values?.author, AttributeLength.author);
      context.document.properties.manager = getString(values?.manager, AttributeLength.manager);

      context.document.properties.customProperties.add("Language", getString(values?.customProperties?.Language, -1));

      context.document.properties.customProperties.add(
        "Classification",
        getString(values?.customProperties?.Classification, -1)
      );
      context.document.properties.customProperties.add("Status", getString(values?.customProperties?.Status, -1));
      context.document.properties.customProperties.add(
        "AuthorsUnit",
        getString(values?.customProperties?.AuthorsUnit, AttributeLength.authorsUnit)
      );
      context.document.properties.customProperties.add(
        "AuthorsTitle",
        getString(values?.customProperties?.AuthorsTitle, AttributeLength.authorsTitle)
      );
      context.document.properties.customProperties.add(
        "ManagersUnit",
        getString(values?.customProperties?.ManagersUnit, AttributeLength.managersUnit)
      );
      context.document.properties.customProperties.add(
        "ManagersTitle",
        getString(values?.customProperties?.ManagersTitle, AttributeLength.managersTitle)
      );
      context.document.properties.customProperties.add(
        "Version",
        getString(values?.customProperties?.Version, AttributeLength.version)
      );

      context.document.properties.customProperties.add(
        "SGGovDocID",
        getString(values?.customProperties?.SGGovDocID, AttributeLength.SGGovDocID)
      );
      context.document.properties.customProperties.add(
        "SGDocumentType",
        getString(values?.customProperties?.SGDocumentType, AttributeLength.SGDocumentType)
      );

      context.document.properties.customProperties.add(
        "SGIssueDateTxt",
        getString(values?.customProperties?.SGIssueDateTxt, -1)
      );

      context.document.properties.customProperties.add(
        "SGIssueDate",
        getString(values?.customProperties?.SGIssueDateTxt, -1)
      );

      var eff = getString(values?.customProperties?.SGEffectiveDateTxt, -1);
      context.document.properties.customProperties.add("SGEffectiveFrom", dtf.FormatToDateTime_SQL(eff));
      context.document.properties.customProperties.add("SGEffectiveDateTxt", eff);
      context.document.properties.customProperties.add("SGEffectiveDate", eff);
      context.document.properties.customProperties.add("SGEffectiveTime", dtf.GetTimePart(eff).replace(":","").replace(" ",""));
      context.document.properties.customProperties.add(
        "SGEffectiveTimeBase",
        getString(values?.customProperties?.SGEffectiveTimeBase, -1)
      );


      // Validity Date - !!! in seperate variable due to multiple data sources
      var val = getString(values?.customProperties?.Valid, -1);
      if (val.toUpperCase() === "UFN") {
        context.document.properties.customProperties.add("SGValidUntil", "UFN");
        context.document.properties.customProperties.add("SGValidity", "UFN");
        context.document.properties.customProperties.add("SGValidityDate", "UFN");
        context.document.properties.customProperties.add("SGValidityTime", "");
        context.document.properties.customProperties.add("SGValidityTimeBase", "");
        context.document.properties.customProperties.add("SGValidUntilBase", "");
      } else {
        context.document.properties.customProperties.add("SGValidUntil", dtf.FormatToDateTime_SQL(val));
        context.document.properties.customProperties.add("SGValidity", val);
        context.document.properties.customProperties.add("SGValidityDate", val);
        context.document.properties.customProperties.add(
          "SGValidityTime",
          getString(dtf.GetTimePart(val), -1).replace(":","").replace(" ","")
        );
        context.document.properties.customProperties.add(
          "SGValidityTimeBase",
          getString(values?.customProperties?.SGValidityTimeBase == undefined ? TimeBase.LT : values?.customProperties?.SGValidityTimeBase, -1)
        );
        context.document.properties.customProperties.add(
          "SGValidUntilBase",
          getString(values?.customProperties?.SGValidityTimeBase == undefined ? TimeBase.LT : values?.customProperties?.SGValidityTimeBase, -1)
        );
      }


      await context.sync();
      setSubmitting(false);
      setDocumentProperties(values);
      setMessage("Successfully updated properties");

    }).catch(function (error) {
      setMessage("Error occured updating your properties");
      console.log("Error: " + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log("Debug info: " + JSON.stringify(error.debugInfo));
      }
    });
  };

  if (!isOfficeInitialized) {
    return (
      <Progress
        title={title}
        logo={require("./../../../assets/logo-filled.png")}
        message="Please sideload your addin to see app body."
      />
    );
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(
      AttributeLength.comments,
      "Must be " + AttributeLength.title.toString() + " characters or less"
    ),
    comments: Yup.string().max(
      AttributeLength.comments,
      "Must be " + AttributeLength.comments.toString() + " characters or less"
    ),
    author: Yup.string().max(
      AttributeLength.author,
      "Must be " + AttributeLength.author.toString() + " characters or less"
    ),
    manager: Yup.string().max(
      AttributeLength.manager,
      "Must be " + AttributeLength.manager.toString() + " characters or less"
    ),
    customProperties: Yup.object().shape({
      Status: Yup.string()
        .test("Check status", "Please choose a correct status", function (value) {
          return value !== undefined ? value in Status : false;
        })
        .required("Required"),
      Language: Yup.string()
        .test("Check language", "Please choose a correct language", function (value) {
          var ll = value !== undefined ? value in Language : false;
          return value !== undefined ? value in Language : false;
        })
        .required("Required"),
      Classification: Yup.string()
        .test("Check confidentiality", "Please choose a correct confidentiality", function (value) {
          return value !== undefined ? value in Classification : false;
        })
        .required("Required"),
      AuthorsUnit: Yup.string().max(
        AttributeLength.authorsUnit,
        "Must be " + AttributeLength.authorsUnit.toString() + " characters or less"
      ),
      AuthorsTitle: Yup.string().max(
        AttributeLength.authorsTitle,
        "Must be " + AttributeLength.authorsTitle.toString() + " characters or less"
      ),
      ManagersUnit: Yup.string().max(
        AttributeLength.managersUnit,
        "Must be " + AttributeLength.managersUnit.toString() + " characters or less"
      ),
      ManagersTitle: Yup.string().max(
        AttributeLength.managersTitle,
        "Must be " + AttributeLength.managersTitle.toString() + " characters or less"
      ),
      Version: Yup.string().max(
        AttributeLength.version,
        "Must be " + AttributeLength.version.toString() + " characters or less"
      ),
      SGGovDocID: Yup.string().max(
        AttributeLength.SGGovDocID,
        "Must be " + AttributeLength.SGGovDocID.toString() + " characters or less"
      ),
      SGDocumentType: Yup.string().max(
        AttributeLength.SGDocumentType,
        "Must be " + AttributeLength.SGDocumentType.toString() + " characters or less"
      ),
      SGIssueDateTxt: Yup.string()
        .test("Verify the issue date", "Invalid format yyyy-MM-dd", function (value) {
          return dtf.TextIsValidDate(value);
        })
        .required("Please enter issue date"),
      SGEffectiveDateTxt: Yup.string().test(
        "Verify the effective date",
        "Invalid format empty or yyyy-MM-dd hh:mm",
        function (value) {
          return value === undefined || value ==="" ? true : dtf.TextIsValidDateTime(value, false);
        }
      ),
      SGEffectiveTimeBase: Yup.string().test("Verify the effective time base", "Select UTC or LT", function (value) {
        return value !== undefined ? value in TimeBase : false;
        //return value === "UTC" ? true : false;
      }),
      Valid: Yup.string().test(
        "Verify the valid until date",
        "Invalid format empty, UFN or yyyy-MM-dd hh:mm",
        function (value) {
          return value === undefined || value ==="" ? true : dtf.TextIsValidDateTime(value, true);
        }
      ),
      SGValidityTimeBase: Yup.string().test("Verify the valid until time base", "Select UTC or LT", function (value) {
        return value !== undefined ? value in TimeBase : false;
        //return value === "UTC" ? true : false;
      }),
    }),
  });

  if (message !== undefined && message !== "") {
    return (
      <div className="centered">
        <p> {message} </p>
        <button
          type="button"
          onClick={() => {
            setMessage("");
          }}
        >
          OK
        </button>
      </div>
    );
  }

  return (
    <div className="ms-welcome">
      <Formik
        initialValues={documentProperties}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(
          { setFieldValue, handleSubmit, errors } // values, errors
        ) => (
          <form onSubmit={handleSubmit}>
            {/*"Errors:" + JSON.stringify(errors)*/}
            {/*JSON.stringify(documentProperties)*/}
            {/*JSON.stringify(documentProperties)*/}
            {/*<OfficeToken />*/}
            <div className="iconRight">
              <a href="https://company-220593.frontify.com/d/j3jZ1FEzTGBT/brand-guidelines#/office-templates/word-templates/editing-skydoc-metadata-1">
              <FontAwesomeIcon icon={faCircleInfo} size="2x" color={ isDev ? "Red" : "DarkGray"}/>
              {/*<img src=".\helpme.jpg" alt="help" id="helpicon"></img>*/}
              </a>
              { isDev ? <><br></br><span className="banner">Development Version 1.0.0!</span></> : "" }
            </div>
            <InputControl label="Title" name="title" />
            <InputControl label="Subject" name="subject" />
            <InputControl label="Abstract" name="comments" multiline />
            {/* Doc Property Author  should be displayed as Manager in AddIN
            Doc Property Manager should be displayed as Owner in AddIN
            - Mail von Ulrich.juergens
            */}
            <hr />
            <PoeplePickerControl
              label="Doc Owner"
              name="manager"
              graphService={graphService}
              fieldDepartment="customProperties.ManagersUnit"
              fieldTitle="customProperties.ManagersTitle"
            />
            <InputControl label="Unit" name="customProperties.ManagersUnit" />
            <InputControl label="Title" name="customProperties.ManagersTitle" />
            <hr />
            <PoeplePickerControl
              label="Doc Manager"
              name="author"
              graphService={graphService}
              fieldDepartment="customProperties.AuthorsUnit"
              fieldTitle="customProperties.AuthorsTitle"
            />
            <InputControl label="Unit" name="customProperties.AuthorsUnit" />
            <InputControl label="Title" name="customProperties.AuthorsTitle" />
            <hr />
            <InputControl label="Doc Type" name="customProperties.SGDocumentType" />
            <InputControl label="Doc Id" name="customProperties.SGGovDocID" />
            <DropdownControl
              label="Confidentiality"
              name="customProperties.Classification"
              options={confidentialityOptions}
            />
            <InputControl label="Version" name="customProperties.Version" />
            <DropdownControl label="Status" name="customProperties.Status" options={statusOptions} />
            <DropdownControl label="Language" name="customProperties.Language" options={languageOptions} />
            <hr />
            <DateComponent
              label="Issue Date"
              date="customProperties.SGIssueDateTxt"
              timeBase=""
              timeBasedOptions={timeBaseOptions}
              onClickEOD={() => {
                setFieldValue("customProperties.SGIssueDateTxt", dtf.GetFormattedDateString(new Date(new Date())));
              }}
              onClickNow={() => {
                setFieldValue("customProperties.SGIssueDateTxt", dtf.GetFormattedDateString(new Date(new Date())));
              }}
              onClickClear={() => {
                setFieldValue("customProperties.SGIssueDateTxt", "");
              }}
            />
            <hr />
            <DateComponent
              label="Effective From"
              date="customProperties.SGEffectiveDateTxt"
              timeBase="customProperties.SGEffectiveTimeBase"
              timeBasedOptions={timeBaseOptions}
              onClickEOD={() => {
                setFieldValue(
                  "customProperties.SGEffectiveDateTxt",
                  dtf.GetFormattedDateTimeStringV1(new Date(new Date().setHours(23, 59, 59, 999)))
                );
              }}
              onClickNow={() => {
                setFieldValue(
                  "customProperties.SGEffectiveDateTxt",
                  dtf.GetFormattedDateTimeStringV1(new Date(new Date()))
                );
              }}
              onClickClear={() => {
                setFieldValue("customProperties.SGEffectiveDateTxt", "");
              }}
            />
            <hr />
            <DateComponent
              label="Valid Until"
              date="customProperties.Valid"
              timeBase="customProperties.SGValidityTimeBase"
              timeBasedOptions={timeBaseOptions}
              onClickEOD={() => {
                setFieldValue(
                  "customProperties.Valid",
                  dtf.GetFormattedDateTimeStringV1(new Date(new Date().setHours(23, 59, 59, 999)))
                );
              }}
              onClickNow={() => {
                setFieldValue(
                  "customProperties.Valid",
                  dtf.GetFormattedDateTimeStringV1(new Date(new Date()))
                );
              }}
              onClickClear={() => {
                setFieldValue("customProperties.Valid", "");
              }}
            />
            <hr />
            {JSON.stringify(errors) !== "{}" && (
              <div className="warning">
                <p>Some fields contain invalid data!</p>
              </div>
            )}
            <DefaultButton type="submit">Submit</DefaultButton>
          </form>
        )}
      </Formik>
    </div>
  );
};
