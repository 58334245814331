import { IPersonaProps } from "@fluentui/react";
import { AuthProvider, AuthProviderCallback, BatchRequestContent, BatchRequestStep, BatchResponseContent, Client, Options } from "@microsoft/microsoft-graph-client";
import { User } from "@microsoft/microsoft-graph-types";
import { Msal2Config, OfficeSSOProvider } from "./authprovider";

export class GraphService {
  private client: Client | undefined;
  private officeSSOProvider: OfficeSSOProvider | undefined;
  private accessToken: string | undefined;

  public constructor(officeConfig: Msal2Config) {
    this.officeSSOProvider = new OfficeSSOProvider(officeConfig);
    const authProvider: AuthProvider = async (callback: AuthProviderCallback) => {
      try {
        if (this.accessToken === undefined && this.officeSSOProvider) {
          try {
            this.accessToken = await this.officeSSOProvider.getAccessToken();
          }
          catch {
            this.accessToken = undefined;
          }
        }
        callback(null, this.accessToken);
      }
      catch (ex) {
        callback(ex, null);
      }
    }
    let options: Options = {
      authProvider,
    };
    this.client = Client.init(options);
  }

  private searchUsers = async (
    search: string,
    limit?: number
  ): Promise<any> => {

    let filter = `accountEnabled eq true and userType eq 'Member'`;
    const searchDisplayName = `"displayName:${encodeURI(search)}"`
    const searchMail = `"mail:${encodeURI(search)}"`
    const searchGivenName = `"givenName:${encodeURI(search)}"`
    const searchSurname = `"surName:${encodeURI(search)}"`
    const otherMails = `"otherMails:${encodeURI(search)}"`
    const userPrincipalName = `"userPrincipalName:${encodeURI(search)}"`
    const company = `"companyName:${encodeURI(search)}"`

    var query = this.client
      .api("/users")
      .filter(filter)
      .orderby("displayName")
      .count(true)

    if (search && search && search?.trim() !== "") {
      query = query.search(searchDisplayName + " OR " + searchMail + " OR " + searchGivenName + " OR " + searchSurname + " OR " + otherMails + " OR " + userPrincipalName + " OR " + company)
    }

    if (limit) {
      query = query.top(limit)
    }

    query.options({ headers: { ConsistencyLevel: 'eventual' } })
    // onPremisesExtensionAttributes
    return query.select("id,displayName,userPrincipalName,onPremisesExtensionAttributes,mail,givenName,surname")
      .get()
  };

  public getUserTitle = async (
    resourceId: string
  ) => {
    try {
      let ret = await this.client
        .api(`/users/${resourceId}?$select=jobTitle`)
        .get();
      return ret.jobTitle;
    } catch (error) {
      return "";
    }
  };

  public getUserDepartment = async (
    resourceId: string
  ) => {
    try {
      let ret = await this.client
        .api(`/users/${resourceId}?$select=department`)
        .get();
      return ret.department;
    } catch (error) {
      return "";
    }
  };

  public getUserExtAttrib2 = async (
    resourceId: string
  ) => {
    try {
      let ret = await this.client
        .api(`/users/${resourceId}?$select=onPremisesExtensionAttributes`)
        .get();
        var ext2 = ret.onPremisesExtensionAttributes;
        if (ext2 !== undefined && ext2.extensionAttribute2 !== undefined) {
          return ext2.extensionAttribute2;
        }
      return "";
    } catch (error) {
      return "";
    }
  };

  getResourcePhoto = async (
    resource: string,
    resourceId: string
  ): Promise<string> => {
    return new Promise<string>(async (resolve, reject) => {
      try {
        let blob = await this.client
          .api(`/${resource}/${resourceId}/photo/$value`)
          //.responseType(ResponseType.BLOB)
          .get();
        //let response = await this.blobToBase64(blob);
        resolve(window.URL.createObjectURL(blob));
      } catch (error) {
        reject(undefined);
      }
    });
  };

  getResourcePhotos = async (
    resource: string,
    resourceIds: string[]
  ): Promise<BatchResponseContent> => {
    return new Promise<BatchResponseContent>(async (resolve, reject) => {
      try {
        const requests: BatchRequestStep[] = resourceIds.map((resourceId) => {
          const request: BatchRequestStep = {
            id: resourceId,
            request: new Request(`/${resource}/${resourceId}/photos/64x64/$value`, {
              method: "GET",
            })
          };
          return request;
        })

        const batchRequestContent = new BatchRequestContent(requests);
        const content = await batchRequestContent.getContent();
        const response = await this.client.api("/$batch").post(content);
        resolve(new BatchResponseContent(response));
      } catch (error) {
        console.error(error);
        reject(null);
      }
    });
  };

  getPeoplePhotos = async (personIds: string[]): Promise<BatchResponseContent> => {
    return await this.getResourcePhotos("users", personIds);
  };

  b64toBlob = async (b64Data: any, contentType: string, sliceSize?: number): Promise<Blob> => {
    contentType = contentType || 'image/png';
    sliceSize = sliceSize || 512;

    let byteCharacters: string = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = _ => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });
  };

  getUserBySearchCriteria = async (
    search: string,
    limitResults: number,
  ): Promise<IPersonaProps[]> => {
    return new Promise<IPersonaProps[]>((resolve, reject) => {
      try {
        this.searchUsers(search, limitResults)
          .then((result) => {
            let users: [User] = result.value;
            resolve(users.map((user) => {
              return {
                id: user.id,
                text: user.givenName === undefined || user.givenName === "" ? (user.surname === undefined || user.surname === "" ? "" : user.surname) : (user.surname === undefined || user.surname === "" ? user.givenName : user.givenName + ' ' + user.surname), // user.displayName,
                primaryText: user.mail
              }
            }));
          })
          .catch((e) => {
            console.log(e);
            reject([]);
          });
      } catch (ex) {
        reject([]);
      }
    });
  };

}
