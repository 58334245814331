import * as React from "react";
import { Field, FieldAttributes, FieldProps } from "formik";
import { TextField } from "@fluentui/react";

const dateMask = [/[1-9]/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

// https://fabricweb.z5.web.core.windows.net/oufr/7.153.2/#/examples/textfield
export const InputControl: React.FunctionComponent<FieldAttributes<any>> = (props) => {
  return (
    <Field {...props}>
      {({ field, meta }: FieldProps) => (
        <TextField
          errorMessage={meta.touched && meta.error ? meta.error : undefined}
          {...field}
          {...props}
        />
      )}
    </Field>
  );
};
