import axios from "axios";

export interface Msal2Config {
    clientId: string;
    ssoUrl: string;
}

export class OfficeSSOProvider {
    protected clientId: string;
    private _ssoUrl: string;

    constructor(config: Msal2Config) {
        this.clientId = config.clientId;
        this._ssoUrl = config.ssoUrl;
    }

    public async getAccessToken(): Promise<string> {
        const clientToken = await OfficeRuntime.auth.getAccessToken();

        try {
            let tokenResponse = await axios({
                url: this._ssoUrl,
                method: 'post',
                headers: { 'Authorization': `Bearer ${clientToken}` }    
            });
            return tokenResponse.data;
        }
        catch (ex) {
            return ex
        }
    }
}