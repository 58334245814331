import { date } from "yup";


export class DateFunctions {

    public isValidDate = (date: Date) => {
        return date instanceof Date && !isNaN(date.valueOf());
        //return !isNaN(myDate.getTime())
    };

    // format yyyy-MM-dd hhmm
    public GetFormattedDateTimeStringV1 = (date: Date) => {
        return !this.isValidDate(date) ? "" : this.GetFormattedDateString(date) + " " + this.GetFormattedTimeStringV1(date);
    };


    // format: yyyy-MM-dd
    public GetFormattedDateString = (date: Date) => {
        if (!this.isValidDate(date)) {
            return "";
        }
        let tmp = date.toLocaleDateString("de-CH", { year: "numeric" }) + "-";
        tmp += date.toLocaleDateString("de-CH", { month: "2-digit" }) + "-";
        tmp += date.toLocaleDateString("de-CH", { day: "2-digit" });
        return tmp;
        // date.toLocaleDateString("de-CH");
    };

    // format hh:mm
    public GetFormattedTimeStringV1 = (date: Date) => {
        return !this.isValidDate(date) ? "" : date.toLocaleTimeString("de-CH", { hour: "2-digit", minute: "2-digit" });
    };
    // format hhmm
    public GetFormattedTimeStringVno = (date: Date) => {
        return this.GetFormattedTimeStringV1(date).replace(":","");
    };

    private regex_dateCH = /^\d{1,2}\.\d{1,2}\.\d{4}$/;
    private regex_dateUS = /^\d{4}\-\d{1,2}\-\d{1,2}$/;
    private regex_time_NoP = /^([0-9]|0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/;
    private regex_time = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

    // format hh:mm or hhmm
    public TextIsValidTime = (text: string) => {
        if (text === undefined || text === "") return false;
        return text.includes(":") ? this.regex_time.test(text) : this.regex_time_NoP.test(text);
    };

    // format LT or UTC
    public TextIsValidTimeBase = (text: string) => {
        return (text.trim().toUpperCase() === "LT" || text.trim().toUpperCase() === "UTC") ? true : false;
    };
    
    // format: yyyy-MM-dd
    public TextIsValidDate = (text: string) => {
        if (text === undefined) { return false; }
        var day = 0;
        var month = 0;
        var year = 0;
        if (text.includes(".")) {
            // Swiss format - not allowed
            return false;
            /*
            if (!regex_dateCH.test(text)) {
              return false;
            }
            var parts = text.split(".");
            day = parseInt(parts[0], 10);
            month = parseInt(parts[1], 10);
            year = parseInt(parts[2], 10);
            */
        } else {
            // international format
            if (!this.regex_dateUS.test(text)) {
                return false;
            }
            var parts = text.split("-");
            day = parseInt(parts[2], 10);
            month = parseInt(parts[1], 10);
            year = parseInt(parts[0], 10);
        }
        if (year < 1000 || year > 3000 || month == 0 || month > 12) {
            return false;
        }
        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
            monthLength[1] = 29;
        }
        return day > 0 && day <= monthLength[month - 1];
    };

    // format yyyy-MM-ddThh:mm:ssZ or yyyy-MM-dd hh:mm or yyyy-MM-dd hhmm or {D/yyyy/MM/dd:HH:mm:ss}
    // or empty
    // if withUFN===true => UFN allowed
    public TextIsValidDateTime = (text: string, withUFN: boolean) => {
        if (text === undefined) return false;    // ? 
        if (text === "") return true; 
        if (withUFN === true && text.toUpperCase().trim() === "UFN" ) return true; 
        if (! this.TextIsValidDate(this.GetDatePart(text))) return false;
        if (! this.TextIsValidTime(this.GetTimePart(text))) return false;
        var data = this.GetTimeBaseFromString(text);
        return data === undefined || data === "" ? true : this.TextIsValidTimeBase(data);
    };


    // format yyyy-MM-ddThh:mm:ssZ or yyyy-MM-dd hh:mm or yyyy-MM-dd hhmm or {D/yyyy/MM/dd:HH:mm:ss}
    public GetDatePart = (text: string) => {
        if (text === undefined || text ==="") { return ""; }
        if (!text.includes("T") && !text.includes(" ") && !text.includes("D")) {
            return this.TextIsValidDate(text) ? text : "";
        }
        if (text.includes("D") || text.includes("{")) {
            var parts = text.split("/");
            if (parts.length < 4) return "";
            var tim = parts[3].split(":");
            return tim[0]===undefined ? "" : parts[1] + "-" + parts[2] + "-" + tim[0];
        }
        var parts = text.includes("T") ? text.split("T") : text.split(" ");
        return parts[0] !== undefined ? parts[0].trim() : "";
    };

    public GetTimeBaseFromString = (text: string) => {
        if (text === undefined) { return ""; }
        if (text.includes("LT")) return "LT";
        if (text.includes("UTC")) return "UTC";
        return ""
    };

    // format yyyy-MM-ddThh:mm:ssZ or yyyy-MM-dd hh:mm or yyyy-MM-dd hhmm or {D/yyyy/MM/dd:HH:mm:ss}
    public GetTimePart = (text: string) => {
        if (text === undefined || text ==="") { return ""; }
        if (!text.includes("T") && !text.includes(" ") && !text.includes("D")) {
            return "";
        }
        if (text.includes("D") || text.includes("{")) {
            var parts = text.split("/");
            if (parts.length < 4) return "";
            var tim = parts[3].split(":");
            return tim[2]===undefined ? "" : tim[1] + ":" + tim[2];
        }
        var parts = text.includes("T") ? text.split("T") : text.split(" ");
        if (text.includes(" ")) {
            if (parts.length===2) {
                return parts[1] !== undefined ? parts[1].trim() : "";
            } else if (parts.length===3) {
                return parts[2] !== undefined ? parts[2].trim() : "";
            } 
            return ""
        }
        return parts[1] !== undefined ? parts[1].replace("Z","") : "";
    };

    public FormatTime = (text: string) => {
        if (!this.TextIsValidTime(text)) return text;
        if (text.includes(":")) return text;
        if (text.length < 4) return text;
        return text[0]+text[1]+":"+text[2]+text[3];
    }

    public FormatToDateTime_ISO = (text: string) => {
        if (!this.TextIsValidDateTime(text, false)) return ""
        var all = this.GetTimePart(text);
        if (!all.includes(":") && all.length===4) {
            all = all[0]+all[1]+":"+all[2]+all[3]
        }
        return this.GetDatePart(text)+"T"+all+":00Z"
    }

    // {D/yyyy/MM/dd:HH:mm:ss}
    public FormatToDateTime_SQL = (text: string) => {
        if (text === undefined) return ""
        if (text.toUpperCase() === "UFN" || text === "") return text;
        var d = this.GetDatePart(text);
        var t = this.GetTimePart(text);
        return t==="" || d ==="" ? "" : "{D/" + d.replace("-","/").replace("-","/") + ":" + t + ":00}";
    }

    // yyyy-MM-dd hh:mm
    public FormatToDateTime_Standard = (text: string) => {
        if (text === undefined || text === "") return ""
        if (!text.includes("D") && text.includes(":")) return text;
        var d = this.GetDatePart(text);
        var t = this.FormatTime(this.GetTimePart(text));
        return t==="" ? d : d + " " + t;
    }

        
    public TEST = async () => {
            const dtf = new DateFunctions();

            
            var tc0 = this.FormatToDateTime_Standard("2022-02-04 0923")
            var tc1 = this.FormatToDateTime_Standard("2022-02-04 09:23")
            var tc2 = this.FormatToDateTime_Standard("{D/2022/02/04:09:23:00}")
            var tc3 = this.FormatToDateTime_Standard("{D/2022/02/04:092300}")
            var tc4 = this.FormatToDateTime_Standard("2022-02-04 0999")
            var s1 = this.FormatToDateTime_SQL("2022-02-04 0923")

            var t0 = this.GetDatePart("{D/2022/04-29:09:01:00}")
            var t1 = this.GetTimePart("{D/2022/04-29:09:01:00}")


            var s2 = this.FormatToDateTime_SQL("{D/2022/02/04:09:23:00}")
            var s3 = this.FormatToDateTime_SQL("D/2022/02/04:09:23:00")
            var s4 = this.FormatToDateTime_SQL("2022-02-04T09:23:00Z")
            var s5 = this.FormatToDateTime_SQL("2022-02-04")
            var s6 = this.FormatToDateTime_SQL("{D/2022/02/04}")

            var n1 = dtf.FormatToDateTime_Standard("2022-02-04 0923")
            var n2 = dtf.FormatToDateTime_Standard("2022-02-04 09:23")
            var n3 = this.FormatToDateTime_Standard("D/2022/02/04:09:23:00")
            var n4 = this.FormatToDateTime_Standard("{D/2022/02/04:09:23:00}")
            var n5 = this.FormatToDateTime_Standard("2022-02-04 09:23")
            var n6 = this.FormatToDateTime_Standard("2022-02-04T09:23:00Z")

            // format yyyy-MM-ddThh:mm:ssZ or yyyy-MM-dd hh:mm or yyyy-MM-dd hhmm or D/yyyy/MM/dd:HH:mm:ss
            var getf1 = this.GetDatePart("D/2022/02/04:09:23:00")
            var getf2 = this.GetTimePart("D/2022/02/04:09:23:00")
            var getf3 = this.GetDatePart("2022-02-04 09:23")
            var getf4 = this.GetTimePart("2022-02-04 09:23")
            var getf5 = this.GetTimePart("2022-02-04 0923")
            var getf6 = this.GetDatePart("2022-02-04T09:23:00Z")
            var getf7 = this.GetTimePart("2022-02-04T09:23:00Z")

            var valf1 = this.TextIsValidDate(this.GetDatePart("{D/2022/02/04:09:23:00}"))
            var valf2 = this.TextIsValidTime(this.GetTimePart("{D/2022/02/04:09:23:00}"))
            var valf3 = this.TextIsValidDate(this.GetDatePart("2022-02-04 09:23"))
            var valf4 = this.TextIsValidTime(this.GetTimePart("2022-02-04 09:23"))
            var valf5 = this.TextIsValidDate(this.GetDatePart("2022-02-04T09:23:00Z"))
            var valf6 = this.TextIsValidTime(this.GetTimePart("2022-02-04T09:23:00Z"))


            var test001 = dtf.isValidDate(new Date()); // True
            var test002 = dtf.isValidDate(new Date("llll")); // false

            var f1 = dtf.GetFormattedDateString( new Date());
            var f2 = dtf.GetFormattedDateTimeStringV1( new Date());
            //var f3 = dtf.GetFormattedDateTimeStringV2( new Date());

            // Date Formats
            var df1 = dtf.TextIsValidDate("2022-01-04") // true
            var df2 = dtf.TextIsValidDate("2022-12-31") // true
            var df3 = dtf.TextIsValidDate("2022-02-28") // true
            var df4 = dtf.TextIsValidDate("2020-02-29") // true

            var df5 = dtf.TextIsValidDate("2022-02-29") // FALSE
            var df6 = dtf.TextIsValidDate("2020-02-31") // FALSE
            

            // validations
            var v01 = dtf.TextIsValidDate("2022-02-04")
            var v02 = dtf.TextIsValidDateTime("2022-02-04 0923", false)
            var v02 = dtf.TextIsValidDateTime("2022-02-04 09:23", false)
            var v03 = dtf.TextIsValidDateTime("2022-02-04T09:23:00Z", false)
            var v05 = dtf.TextIsValidDateTime("ufn", true)

            var v06 = dtf.TextIsValidDateTime("ufn", false) // FALSE
            var v07 = dtf.TextIsValidDateTime("2022-02-04 hg 0923", true) // FALSE

    };

}